import { RadioMetaData } from "@/components/player/type";
import { BASE_API_URL } from "@/utils/env";
import axios from "axios";

export const fetchRadioMetaData = async (
  radioName: string
): Promise<RadioMetaData | string> => {
  let radioMetaDataOrError: RadioMetaData | string = "";
  try {
    const response = await axios.get(`${BASE_API_URL}metadata/${radioName}/`);

    if ([200, 201].includes(response.status)) {
      radioMetaDataOrError = response.data;
    } else {
      radioMetaDataOrError =
        "Erreur inattendu: S'il vous plait veuillez raffraichir la page";
    }
  } catch (error) {
    console.log(error);
  }
  return radioMetaDataOrError;
};
